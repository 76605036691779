
import Vue, { PropType } from "vue";

import { DialogI } from "@/types/dialog";

import BaseDialog from "@/components/dialog/BaseDialog.vue";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "CustomPopup",
  components: {
    BaseDialog,
    Btn,
  },

  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },
});
